import { useState } from 'react';
import { useRouter } from 'next/router';
import { Grid, Typography } from '@mui/material';

import FormBox from '../../components/input/FormBox';
import InteractiveButtonWithAccordionText from '../../components/InteractiveButtonWithBelowText';
import { getLandingTemplate } from '../../components/layout/LandingTemplate';
import useClaspBanner from '../../hooks/useClaspBanner';
import env from '../../lib/env';
import { PosthogFeatureFlags } from '../../lib/env/posthogFeatureFlags';
import routes from '../../lib/routes';
import { PageWithLayout } from '../../types/PageWithLayout';

import { useFeatureFlagEnabled } from 'posthog-js/react';

const BaseWelcomePage: PageWithLayout = () => {
  const router = useRouter();
  const isOTPPFlowEnabled = useFeatureFlagEnabled(PosthogFeatureFlags.OTPP);

  const [ selectedOption, setSelectedOption ] = useState<
    { key: string; url: string } | undefined
  >();

  const nextEnabled = selectedOption && selectedOption.url;

  const handleNext = () => {
    if (nextEnabled) {
      router.push(selectedOption?.url as string);
    }
  };

  useClaspBanner();

  const handleButtonClick = (key: string, url: string) =>
    setSelectedOption((selectedOption) =>
      selectedOption?.key !== key ? { key, url } : undefined,
    );

  return (
    <Grid>
      <FormBox
        backHidden={true}
        dataCy='continue-form-box'
        maxWidth={600}
        nextCaption="Let's get started"
        nextDisabled={!nextEnabled}
        onNextClick={handleNext}
        subtitle={''}
      >
        <Grid
          display='flex'
          flexDirection='column'
          item
          justifyContent='center'
          xs={12}
        >
          <Typography textAlign='center' variant='h2'>
            What are you looking for?
          </Typography>
          {
            env.feature.D2C_ENABLED && (
              <InteractiveButtonWithAccordionText
                accordionText={'You are an Undergraduate, Graduate, or PhD student within 2 years of graduation.'}
                buttonText={'BA/BS, MA/MS, or PhD Program'}
                dataCy={'welcome-education-button'}
                onClick={
                  () =>
                    handleButtonClick(
                      'program',
                      routes.internal.eligibilityUniversityProgram.url,
                    )
                }
                selected={selectedOption?.key === 'program'}
              />
            )
          }
          <InteractiveButtonWithAccordionText
            accordionText={'You are enrolled in a bootcamp or trade school'}
            buttonText={isOTPPFlowEnabled ? 'Financing for my educational institution' : 'Financing for my bootcamp or trade school program'}
            dataCy={'welcome-bootcamp-button'}
            onClick={
              () =>
                handleButtonClick('bootcamp', routes.internal.welcomeBootcamp.url)
            }
            selected={selectedOption?.key === 'bootcamp'}
          />
          {
            env.feature.APPLICANT_WELCOME_FLOW && (
              <InteractiveButtonWithAccordionText
                accordionText={'Find employer sponsorship opportunities'}
                buttonText={'Employer sponsorship for my degree program'}
                dataCy={'welcome-sponsorship-button'}
                onClick={
                  () =>
                    handleButtonClick('sponsorship', routes.internal.welcomeSponsorship.url)
                }
                selected={selectedOption?.key === 'sponsorship'}
              />
            )
          }
        </Grid>
      </FormBox>
    </Grid>
  );
};

BaseWelcomePage.getLayout = getLandingTemplate;

export default BaseWelcomePage;
