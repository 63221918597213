import { MouseEventHandler } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import Typography from '@mui/material/Typography';

interface InteractiveButtonWithAccordionTextProps {
  selected: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  buttonText: string;
  accordionText: string;
  dataCy?: string;
}

const InteractiveButtonWithAccordionText = ({
  selected,
  onClick,
  buttonText,
  accordionText,
  dataCy,
}: InteractiveButtonWithAccordionTextProps): JSX.Element => {
  const buttonProps: ButtonProps = selected
    ? { color: 'primary', variant: 'contained' }
    : { variant: 'outlined' };

  return (
    <>
      <Button data-cy={dataCy} onClick={onClick} sx={{ marginTop: 5 }} {...buttonProps}>
        {buttonText}
      </Button>
      {
        selected && (
          <Typography color='black' px={2} sx={{ marginTop: 3 }}>
            {accordionText}
          </Typography>
        )
      }
    </>
  );
};

export default InteractiveButtonWithAccordionText;
