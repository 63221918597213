import { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { claspBannerOpenState, hasClaspBannerBeenOpened } from '../state/atoms/claspBannerOpenState';

// TODO remove after Clasp cut-over SSP-2819

const useClaspBanner = (): void => {
  const [ hasBannerBeenOpened, setHasBannerBeenOpened ] = useRecoilState(hasClaspBannerBeenOpened);
  const setClaspBannerOpen = useSetRecoilState(claspBannerOpenState);

  useEffect(() => {
    if (hasBannerBeenOpened) return;
    setClaspBannerOpen(true);
    setHasBannerBeenOpened(true);
  }, [ hasBannerBeenOpened, setClaspBannerOpen, setHasBannerBeenOpened ]);
};

export default useClaspBanner;
